(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/module-builder/component-registry/assets/component-registry.js') >= 0) return;  svs.modules.push('/components/module-builder/component-registry/assets/component-registry.js');
"use strict";

var _svs$moduleBuilder$da, _svs$moduleBuilder;
function _classPrivateMethodInitSpec(e, a) { _checkPrivateRedeclaration(e, a), a.add(e); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }

const {
  componentRegistry
} = (_svs$moduleBuilder$da = (_svs$moduleBuilder = svs.moduleBuilder) === null || _svs$moduleBuilder === void 0 ? void 0 : _svs$moduleBuilder.data) !== null && _svs$moduleBuilder$da !== void 0 ? _svs$moduleBuilder$da : {};
const {
  getLogger
} = svs.core.log;
const log = getLogger('module-builder:component-registry');
const NullComponent = () => null;
var _registry = new WeakMap();
var _loadedComponents = new WeakMap();
var _ComponentRegistry_brand = new WeakSet();
class ComponentRegistry {
  constructor() {
    let registry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _classPrivateMethodInitSpec(this, _ComponentRegistry_brand);
    _classPrivateFieldInitSpec(this, _registry, {});
    _classPrivateFieldInitSpec(this, _loadedComponents, {});
    _classPrivateFieldSet(_registry, this, registry);
  }
  getComponent(id) {
    if (_classPrivateFieldGet(_loadedComponents, this)[id]) {
      return _classPrivateFieldGet(_loadedComponents, this)[id];
    }
    try {
      const reference = _assertClassBrand(_ComponentRegistry_brand, this, _parseComponentReference).call(this, _classPrivateFieldGet(_registry, this)[id]);
      _classPrivateFieldGet(_loadedComponents, this)[id] = reference;
      return reference;
    } catch (err) {
      log.warn("Unable to load component from registry with key: ".concat(id, ". Error: ").concat(err));
      return NullComponent;
    }
  }
}
function _parseComponentReference(reference) {
  if (!reference) {
    return NullComponent;
  }
  return reference.split('.').reduce((o, val) => o[val], window);
}
svs.components.moduleBuilder.componentRegistry = new ComponentRegistry(componentRegistry);

 })(window);